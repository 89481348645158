<template>
    <div class="vertical-wrapper">
        <div class="horizontal-wrapper navbar" style="flex: 0 0 auto; margin: 2.5pt">
            <div class="placeholder"></div>
            <router-link class="item" :to="{name: 'user-files-hours'}">Stunden Tabelle</router-link>
            <div v-if="isadmin == 2" class="placeholder"></div>
            <router-link class="item" :to="{name: 'user-files-sick'}">Krankheitstage</router-link>
            <div class="placeholder"></div>
            <router-link v-if="isadmin == 2" class="item" :to="{name: 'user-files-hours-diagram'}">Stunden Diagramm</router-link>
            <div class="placeholder"></div>
            <router-link class="item" :to="{name: 'user-files-projects'}">Projekte</router-link>
            <div class="placeholder"></div>
        </div>
        <router-view></router-view>
    </div>
</template>
<script>

export default {
    name: 'user-files',
    props: ['id', 'name'],
    computed:{
        isadmin(){
            return this.$store.getters.isadmin
        }
    },
    components:{
    },
    methods:{
    
    },
    sockets:{
     
    },
    
    mounted: function() {
    },
    data(){
        return{
           
        }
    },
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            vm.$store.commit('set-show-date', true)
        })
    }
}
</script>
