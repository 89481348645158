<template>
    <div class="text-with-value horizontal-wrapper">
        <h2>{{text}}</h2>
        <div class="placeholder"></div>
        <div style="flex: 0 0 auto;">
            <h2 v-on:click="editMode = editable" class="value" :class="{'hide':editMode}">{{value + unit}}</h2>
            <md-field :class="{'hide':!editMode}"  >
                <label for="">hours</label>
                <md-input ref="value-input" class="md-primary" v-model="textValue" :autofocus="editMode" v-on:keyup="key" v-on:blur="closeEditor"></md-input>
            </md-field>
        </div>
    </div>
</template>
<script>
export default {
    name: 'text-with-value',
    props: {'text':String, 'value':String, 'unit':String, 'editable':{type: Boolean, default:false}},
    methods: {
        closeEditor(){
            this.editMode = false
            this.$emit('value-changed', this.textValue)
        },
        key(e){
            if(e.key== 'Enter')
                this.closeEditor()

        }
    },
    watch: {
        value(){
            this.textValue = this.$props.value
        },
        editMode(){
            if(this.editMode){
                 setTimeout(() => {
                    this.$refs['value-input'].$el.focus();
                });
            }
        }
       
    },
    data(){
        return{
            editMode: false,
            textValue:this.$props.value
        }
    }
}
</script>
<style>
.text-with-value h2{
    margin: 0;
}
.text-with-value h2.value{
    font-weight: 1000;
}
</style>