<template>
    <div class="vertical-wrapper" style="margin: 20px">   
        <h3>Zugänge</h3>
        <div class="vertical-wrapper">
              <md-field style="flex: 0 0 auto;">
                    <label>Bezeichner:</label>
                    <md-input v-model="search_access" ></md-input>
            </md-field>
            <div class="access-list vertical-wrapper" style="flex: 0 0 auto; overflow-y: scroll; ">
                <access-component v-for="acc in access.filter(e => e.name.includes(search_access))" :key="acc.key" :access="acc"></access-component>
            </div>
            <div class="placeholder"></div>
            <div style="flex: 0 0 auto">
                <md-button class="md-primary" v-on:click="access.push({id: -1, name:'', key: uuidv4()})">hinzufügen</md-button>
            </div>
        </div>
    </div>
</template>
<script>
import accessComponentVue from '../components/access-component.vue'
import { v4 as uuidv4 } from 'uuid';

export default {
    name:'accesses',
    props: ['id'],
    components:{
        'access-component':accessComponentVue
    },
    methods: {
        uuidv4:uuidv4,
    },
    sockets:{
        'set-accesses':function({accesses}){
            accesses.forEach(e => e.key = uuidv4());
            this.access = accesses
        }
    },
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            vm.$store.commit('set-show-date', false)
            if(from.path == '/')
                vm.$socket.emit('get-users', {token:vm.$gettoken()})
            if(vm.$props.id){

                vm.$socket.emit('get-accesses', {token: vm.$gettoken()})
            }
            next();
        }) 
    },
    data(){
        return{
            search_access: '',
            access:[ {id: 4, name:'Enwatmon', key: '12345'}]
        }
    }
}
</script>
<style>

</style>