<template>

    <div class="user-evaluation"> 
        <div class="horizontal-wrapper">
            <div class="placeholder"></div>
            <h2>Stundenzettel</h2> 
            <div class="placeholder"></div>
            <md-button class="md-primary" v-on:click="exporting">Exportieren</md-button>
            <div class="placeholder"></div>
            <md-button class="md-primary" v-on:click="abschluss" :class="{'hide':!$getadmin()}">Abschließen</md-button>
            <div class="placeholder"></div>

        </div>
        <month-closed-tag :locked="locked"></month-closed-tag>

        <div ref="spreadsheet"></div>

        <vue-html2pdf
             :html-to-pdf-options="{
                showLayout: false,
                floatLayout: false,
                enableDownload: true,
                previewModal: false,
                paginateElementsByHeight: 1400,
                margin: 5,
                filename: name.replace('.', '-') + '-' +  this.$moment(this.$getdate()).format('MMMM-YYYY') + '.pdf',
                pdfQuality: 2,
              
                jsPDF: {
                    unit: 'mm',
                    format: 'a4',
                    orientation: 'landscape'
                }
            }"
            :show-layout="false"
            :float-layout="false"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            :filename="name.replace('.', '-') + '-' +  this.$moment(this.$getdate()).format('MMMM-YYYY') + '.pdf'"
            :pdf-quality="2"
            :margin="5"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="1000px"

            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            v-on:hasDownloaded="generatePFD = false"
            ref="html2Pdf"
    >
        <section slot="pdf-content" class="pdf-content" :class="{'hide':!generatePFD}">
            <div class="horizontal-wrapper" style="width: 1200px">
                <div class="vertical-wrapper">
                    <h4>Dokumentation der</h4>
                    <h4 style="marign-top: 0">täglichen Arbeitszeiten</h4>
                </div>

                <div class="vertical-wrapper">
                    <h4>Name:</h4>
                    <h4>Monat/Jahr:</h4>
                </div>
                <div class="placeholder"></div>
                <div class="vertical-wrapper">
                    <h4>{{name.replace('.', ' ')}}</h4>
                    <h4>{{this.$moment(this.$getdate()).format('MMMM YYYY') }}</h4>
                </div>
                <div class="logo icon" style="flex: 0 0 100pt; " >
                    
                </div>

            </div>
            <div ref="times-table" class="times-table">

            </div>
            <div class="horizontal-wrapper"> 
                <div>
                    <h4>Summe:</h4>
                </div>
                <div>
                    <h4>{{workSum + ' h'}}</h4>
                </div>
            </div>
        </section>

    </vue-html2pdf>
    </div>
</template>
<script>
import jexcelStyle from "jexcel/dist/jexcel.css"; // eslint-disable-line no-unused-vars
import jexcel from "jexcel"; // eslint-disable-line no-unused-vars
import VueHtml2pdf from 'vue-html2pdf'
import monthClosedTagVue from '../components/month-closed-tag.vue';


export default {
    name: 'user-evaluation',
    props: ['id', 'name'],
    components: {
        VueHtml2pdf,
        'month-closed-tag':monthClosedTagVue
    },
    methods:{
        hasStartedGeneration(){},
        hasGenerated(){
            this.generatePFD = false
        },
        onProgress(){

        },
        exporting(){
            this.generatePFD = true

            this.$refs['times-table'].innerHTML = ''
            this.timesTable = jexcel(this.$refs['times-table'], {
                    data:[[ '08:00', '08:05', '08:04', '08:05',  ]],
                    columns:[
                        { title:'Tag', width:75 },
                        { title:'AZ Start', width:75 },
                        { title:'Pause Start', width:75 },
                        { title:'Pause Ende', width:75 },
                        { title:'Pause Dauer', width:75 },
                        { title:'AZ Ende', width:75 },
                        { title:'AZ Gesamt', width:75 },
                        { title:'AZ mit Zuschläge', width:110 },
                        { title:'*', width:40 },
                        { title:'Stundenabzug', width:80 },
                        { title:'Urlaub', width:60 },

                        { title:'Auslöse', width:55, type:'checkbox' },
                        { title:'B. b.', width:50, type:'hidden' },
                        { title:'B. g.', width:50, type:'checkbox' },

                        { title:'Bemerkung', width:130 },
                        { title:'id', width:0, type: 'hidden' },


                    ]  
                });
            this.timesTable.setData(this.spreadsheet.getData())
            this.timesTable.hideIndex()
            Object.assign(this, this.timesTable);

            this.$refs.html2Pdf.generatePdf()
        },
        abschluss(){
            if(this.$moment(this.$getdate()).format('MMMM YYYY') == this.$moment().format('MMMM YYYY')){
                this.$swal({
                    icon:'error',
                    title: this.$moment(this.$getdate()).format('MMMM YYYY') + ' läuft noch',
                    text: 'Der aktuelle Monat kann nicht abgeschlossen werden',

             })
             return
            }

            this.$swal({
                icon:'question',
                title: this.$moment(this.$getdate()).format('MMMM YYYY') + ' Abschließen?',
                text: 'Wenn Sie einen Monat abschließen können Datensätze nicht mehr bearbeitet werden!',
                showDenyButton:true,
                showOkButton:true,
                denyButtonText: 'nein',
                okButtonText: 'Ja',

            }).then((e)=> {
                if(!e.isConfirmed)
                    return

                this.$socket.emit('close-month-for-user', {token:this.$gettoken(), user:{id:this.$props.id}, date: this.$getdate()} ); 
                setTimeout(()=>{

                    this.$socket.emit('get-user-evaluation', {token: this.$gettoken(), user:{ id:this.$props.id, }, date:this.$getdate()})
                }, 100)

            }) 
        },
        onafterchanges(html, cell){
            const changed_row = this.spreadsheet.getRowData(cell[0].row)
            const day_number = (parseInt(cell[0].row) + 1)
            const day = this.$moment(this.$getdate()).format('YYYY-MM-') + ((day_number < 10)? ('0' + day_number): day_number)

            const transaction_type_match = this.transaction_types.find(e => e.text == changed_row[8])
            if(!transaction_type_match){
                console.log('no match', changed_row)
                return
            }
            const id = (changed_row[15] || !changed_row[15]=='')? parseInt(changed_row[15]) : -1;

            this.$socket.emit('save-user-transaction',  {token: this.$gettoken(), user: { id:this.$props.id }, 
                                                        transaction: 
                                                        {id: id, date: day, transaction_type: transaction_type_match.value, 
                                                        start: changed_row[1], 
                                                        break_start: changed_row[2], 
                                                        break_stop: changed_row[3], 
                                                        stop: changed_row[5], 
                                                        hours_minus: changed_row[9],
                                                        vacation:changed_row[10],
                                                        expenses: changed_row[11] == 'ja', 
                                                        bonus_applied: changed_row[12],
                                                        bonus_granted: changed_row[13],
                                                        
                                                        customer_sight: changed_row[14]} }) 
            
            setTimeout(()=>{
                this.$socket.emit('get-user-evaluation', {token: this.$gettoken(), user:{ id:this.$props.id, }, date:this.$getdate()})
            }, 100)       
        }
    },
    data(){
        const transaction_types = [
                { value:'work', text:'-'}, 
                { value:'sick', text:'K'}, 
                { value: 'child-sick', text: 'KK' }, 

                { value:'school', text:'BS'},
                { value:'vacation', text:'U'},
                { value:'unpaid_vacation', text:'UU'},
                { value:'holiday', text:'F'},
                { value:'overtime_reduction', text:'ÜA'},
                { value:'hourly_absent', text:'SA'},
                { value:'hourly_vacation', text:'SU'},


            ]
        return{
            workSum: 0,
            locked: false,
            generatePFD:false,
            transaction_types: transaction_types,
            spreadsheet: null,
            options:{
                data:[[ '08:00', '08:05', '08:04', '08:05',  ]],
                columns:[
                    { title:'Tag', width:80 }, //0

                    { title:'AZ Start', width:100 },
                    { title:'Pause Start', width:100 },
                    { title: 'Pause Ende', width: 100 }, //3
                    { title:'Pause Dauer', width:100 },
                    
                    { title:'AZ Ende', width:100 },
                    
                    { title:'AZ Gesamt', width:100 },
                    { title: 'AZ mit Zuschläge', width: 150 }, //7
                    { title:'*', width:80, type:'dropdown',  source: transaction_types.map(e => e.text) },
                    { title:'Stundenabzug', width:100 },
                    { title:'Urlaub', width:80 },

                    { title: 'Auslöse', width: 100 }, //11

                    { title:'Bonus beantragt', width:100, type:'checkbox' },
                    { title:'Bonus genehmigt', width:100, type:'checkbox' },

                    { title:'Bemerkung', width:150 }, 

                    { title: 'id', width: 0, type: 'hidden' },//15


                ]   ,
                onafterchanges:this.onafterchanges,
                onbeforepaste: (html, cell, x, y) =>{ 
                    console.log(html, cell, x, y);
                }
               
            }
        }
    },
    beforeRouteEnter (to, from, next) { 

        next(vm => { 
            vm.$store.commit('set-show-date', true)

            if(from.path == '/')
                vm.$socket.emit('get-users', {token:vm.$gettoken()})

            if(vm.$props.id){

                vm.$socket.emit('get-user-evaluation', {token: vm.$gettoken(), user:{ id:vm.$props.id, }, date:vm.$getdate()})
            }

            next();
        }) 
    },
    mounted: function() {
        this.spreadsheet = jexcel(this.$refs["spreadsheet"], this.options);
        Object.assign(this, this.spreadsheet);
    },
    sockets:{
        'set-user-evaluation':function({rows, workSum, locked}){
            this.workSum = Math.round(workSum * 100) / 100
            rows.forEach(e => e[0] = this.$moment(e[0]).format("ddd DD") )
            
            rows.forEach(e => { e[8] = (e[8] || e[8]=='')? this.transaction_types.find(tt => tt.value==e[8]).text: '-' } )
            this.spreadsheet.setData(rows)
            const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
            const style = rows.reduce((arr, e, i) => {

                if(e[16])
                    e.forEach((e, index) => arr[letters[index] + (i + 1) ] = `background-color: yellow;`)

                return arr
            }, {})
            this.spreadsheet.setStyle(style)
            this.locked = locked? true: false
        }, 
        'close-month-result': function ({ balance_integrity }){
            if(!balance_integrity){
                this.$swal.fire({
                    icon: 'error',
                    title: 'Monat nicht abgeschlossen',
                    text: 'Ein Monat zwischen Einstellungsdatum und dem Abschlussdatum ist nicht abgeschlossen'
                })
            }
        }
        
    }
}
</script>
<style >
.user-evaluation{
    max-height: 100%;
}
.times-table{
    font-size: 9pt;
}
.pdf-content{
    margin: 15pt;
}
.pdf-content h4{
    margin: 5pt;
}
.logo{
    background-image: url('../assets/logo_enwat.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
</style>