<template>

    <div class="horizontal-wrapper" style="flex: 0 0 auto; margin-top: 20pt"> 
        <div class="placeholder" style="flex: 2 0 0"></div>
        
        <md-button class="md-primary" v-on:click="showDialog = true">Auswahl</md-button>
        <div class="placeholder"></div>

        <md-field style="flex: 0 0 auto; width:auto; margin: auto 5pt">
            <label for="">Zeitraum</label>
            <md-select :value="timescale" v-on:md-selected="e => {$emit('update:timescale', e); $emit('refresh')}">
                <md-option v-for="ts in timescales" :key="ts.key" :value="ts.value">{{ts.name}}</md-option>
            </md-select>
        </md-field>
       
        <div class="placeholder"></div>
        <md-button class="md-primary" v-on:click="$emit('export')"> Exportieren</md-button>
        <div class="placeholder" style="flex: 2 0 0"></div>


        <md-dialog :md-active.sync="showDialog">
            <div class="horizontal-wrapper" style="flex: 0 0 auto;">
                <md-dialog-title style="display: inline">Benutzer Auswahl</md-dialog-title>
        
                <md-dialog-actions>
                    <md-button class="md-primary" @click="showDialog = false; ">Close</md-button>
                    <md-button class="md-primary" @click="saveSelection">Save</md-button>
                </md-dialog-actions>
            </div>
            <div style="padding: 5pt; flex: 1 0 0;">
                <selection-list :items="userSelection" :top-selection-all="true" :search-bar="true"></selection-list>
        
            </div>
        
        </md-dialog>
    </div>
</template>
<script>
import selectionListVue from './VueComponents/selection-list.vue';

export default {
    name: 'user-file-header',
    props: ['timescale',],
    components:{
        'selection-list': selectionListVue,

    },
    methods: {
        saveSelection(){
            this.$emit('refresh')
            this.showDialog = false
        }
    },
    computed:{
        user() {
            return this.$store.getters.getUser
        },
        userSelection:{
            get(){
                return this.$store.getters.getUserSelection
            }
        }
    },
    data(){
        return{
            showDialog: false,
            selectedTimeScale: 'month',
            timescales: [
                { value: 'month', name: 'Monat', key: this.$uuid.v4() },
                { value: 'year', name: 'Jahr', key: this.$uuid.v4() },
                { value: 'all', name: 'Alles', key: this.$uuid.v4() },
            ]
        }
    }
}
</script>