<template>
    <div>
        <div v-ripple class="horizontal-wrapper project md-primary md-theme-default" :class="{'clicked': clicked}"
            v-on:click="openProject" style="cursor:pointer">
            <h4 class="full-opacity">{{project.name}}</h4>
        </div>

        <md-dialog :md-active.sync="showDialog">
            <div style="height: 400pt; min-width: 800pt">
                <div class="horizontal-wrapper" style="flex: 0 0 auto;">
                    <md-dialog-title style="display: inline; padding-right: 0; padding-left: 2.5pt;">Projektauswertung
                    </md-dialog-title>
                    <div style="flex: 1 0 0"></div>
                    <md-dialog-actions style="flex: 0 0 auto;  padding-left: 0;">
                        <md-button class="md-primary" @click="generatePdf(); $emit('project-close')">Exportieren
                        </md-button>
                        <md-button v-if="changeDetected" class="md-primary" @click="saveProjectDetails" >Save</md-button>

                        <md-button class="md-primary" @click="showDialog = false; $emit('project-close')">Close
                        </md-button>
                    </md-dialog-actions>
                </div>
                <md-tabs md-dynamic-height>
                    <md-tab md-label="Personen" v-on:click="sectionSelected = 'persons'">

                    </md-tab>
                    <md-tab md-label="Abteilungen" v-on:click="sectionSelected = 'departments'">

                    </md-tab>
                    <md-tab md-label="Detailiert" v-on:click="sectionSelected = 'details'">

                    </md-tab>

                </md-tabs>
                <vue-html2pdf :html-to-pdf-options="{
                        showLayout: false,
                        floatLayout: false,
                        enableDownload: true,
                        previewModal: false,
                        paginateElementsByHeight: 1400,
                        margin: 5,
                        filename: 'ProjektAuswertung-' +  projectnr + '_' + projectname + '.pdf',
                        pdfQuality: 2,
                    
                        jsPDF: {
                            unit: 'mm',
                            format: 'a4',
                            orientation: 'landscape'
                        }
                    }" :show-layout="false" :float-layout="false" :enable-download="true" :preview-modal="false"
                    :paginate-elements-by-height="1400"

                    :filename="'Stunden-' +  this.$moment(this.$getdate()).format('MMMM-YYYY') + '.pdf'"
                    
                    :pdf-quality="2" :margin="5" 
                    pdf-format="a4" 
                    pdf-orientation="landscape" 
                    pdf-content-width="1090px"
                    @startPagination="printPDF" @hasDownloaded="hasDownloaded" ref="html2Pdf" style="overflow-y: scroll; max-height: 400px;">

                    <section ref="pdf-content" slot="pdf-content">
                        <div ref="project-evaluation-content" class="vertical-wrapper" style="margin: 20px 30px"
                            :class="{'hide':sectionSelected != 'persons' && !pdfgenerating}">
                            <div class="horizontal-wrapper project-header" style="flex: 0 0 auto ">
                                <div class="vertical-wrapper">
                                    <h4 style="font-weight: normal">Projektnr:</h4>
                                    <h4 style="font-weight: normal">Projektname:</h4>
                                </div>
                                <div class="vertical-wrapper">
                                    <h4>{{projectnr}}</h4>
                                    <h4>{{projectname}}</h4>
                                </div>
                                <div class="placeholder" style="flex: 2 0 0 ">

                                </div>
                                <div class="vertical-wrapper">
                                    <h4 style="font-weight: normal">Erstellt am:</h4>
                                    <h4 style="font-weight: normal">Stunden gesamt:</h4>
                                </div>
                                <div class="vertical-wrapper">
                                    <h4>{{projectCreated}}</h4>
                                    <h4>{{projectHours}}h</h4>
                                </div>
                                <div class="logo" style="flex: 0 0 70pt" :class="{'hide':!pdfgenerating}"> </div>
                            </div>

                            <div class="horizontal-wrapper">
                                <div class="vertical-wrapper">
                                    <h4>Stunden pro Person</h4>
                                    <div ref="times-per-person-table">

                                    </div>
                                </div>
                                <div class="vertical-wrapper">
                                    <h4>Stunden Anteilig</h4>
                                    <div ref="pie-chart" style="min-height: 250pt">

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="html2pdf__page-break" />
                        <div ref="project-evaluation-content" class="vertical-wrapper" style="margin: 20px 30px"
                            :class="{'hide':sectionSelected != 'departments' && !pdfgenerating}">
                            <div class="horizontal-wrapper project-header" style="flex: 0 0 auto ">
                                <div class="vertical-wrapper">
                                    <h4 style="font-weight: normal">Projektnr:</h4>
                                    <h4 style="font-weight: normal">Projektname:</h4>
                                </div>
                                <div class="vertical-wrapper">
                                    <h4>{{projectnr}}</h4>
                                    <h4>{{projectname}}</h4>
                                </div>
                                <div class="placeholder" style="flex: 2 0 0 ">

                                </div>
                                <div class="vertical-wrapper">
                                    <h4 style="font-weight: normal">Erstellt am:</h4>
                                    <h4 style="font-weight: normal">Stunden gesamt:</h4>
                                </div>
                                <div class="vertical-wrapper">
                                    <h4>{{projectCreated}}</h4>
                                    <h4>{{projectHours}}h</h4>
                                </div>
                                <div class="logo" style="flex: 0 0 70pt" :class="{'hide':!pdfgenerating}"> </div>
                            </div>

                            <div class="horizontal-wrapper">
                                <div class="vertical-wrapper">
                                    <h4>Stunden pro Abteilung</h4>
                                    <div ref="times-per-department-table">

                                    </div>
                                </div>
                                <div class="vertical-wrapper">
                                    <h4>Stunden Anteilig</h4>
                                    <div ref="pie-chart-department" style="min-height: 300pt">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="html2pdf__page-break" />
                        <div ref="project-evaluation-content" class="vertical-wrapper" style="margin: 20px 30px"
                            :class="{'hide':sectionSelected != 'details' && !pdfgenerating}">
                            <project-header :projectnr="projectnr" :projectCreated="projectCreated" :projectname="projectname" :projectHours="projectHours" :projectRuntime="projectRuntime" :projectStartEndTime="projectStartEndTime" :pdfgenerating="pdfgenerating"/>
             

                            <div class="horizontal-wrapper">
                                <div class="vertical-wrapper" >
                                    <h4>Detailierte Auflistung der Projektbuchung</h4>
                                    <div ref="details-table">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </vue-html2pdf>
            </div>
        </md-dialog>
    </div>
</template>
<script>
import jexcelStyle from "jexcel/dist/jexcel.css"; // eslint-disable-line no-unused-vars
import jexcel from "jexcel"; // eslint-disable-line no-unused-vars
import { GoogleCharts }  from 'google-charts';
import VueHtml2pdf from 'vue-html2pdf'
import projectHeaderVue from './project-header.vue';


let spreadsheetPersonen;
let spreadsheetDepartments;
let spreadsheetDetails;
export default {
    name: 'project-evaluation-details',
    props: {project:Object, departments:Array, users: Array},
    components:{ VueHtml2pdf, 'project-header':projectHeaderVue },
    methods:{
        openProject(){
            this.showDialog = true
            //damit tabelle bei der response gemounted ist
            this.$nextTick(() => {
                spreadsheetPersonen = jexcel(this.$refs["times-per-person-table"], this.options);
                //Object.assign(this, this.spreadsheet);
                spreadsheetDepartments = jexcel(this.$refs["times-per-department-table"], { 
                    data:[[ '', '00:00']],
                    columns:[
                        { title:'Abteilung', width:170 },
                        { title:'Stunden', width:100 },
                    ]
                });
                spreadsheetDetails = jexcel(this.$refs["details-table"], { 
                    data:[[ '', '']],
                    columns:[
                        { title: 'id', width: 20, type: 'hidden' },
                        { title:'Date', width:90 },
                        { title:'Name', width:170, type: 'dropdown', source: this.users.map(e => e.name ) },
                        { title:'Abteilung', width:170, type:'dropdown', source : [...this.departments.map(e => e.name), 'sonstige' ]  },
                        { title:'Stunden', width:100 },
                        { title: 'Nachtrag', width: 80, type: 'checkbox' },

                        { title:'Bemerkung', width:350 },

                    ],
                    onafterchanges: this.onafterchanges,
                    ondeleterow: this.onafterchanges
                });

                this.$socket.emit('get-project-evaluation-details', {token: this.$gettoken(), project:this.project})

            })
        },
        timeToSeconds(time){
            return time.split(':').reduce((arr, e, i, array) => {
                return arr + parseInt(e) * Math.pow(60,  array.length - i)
            }, 0);
        },
        generatePdf(){
            this.pdfgenerating = true;
            this.$refs.html2Pdf.generatePdf()
        },
        hasDownloaded(){
            this.pdfgenerating = false;
        },
        printPDF(){
        },
        onafterchanges(){
            this.changeDetected = true

            
        },
        saveProjectDetails(){
            const table = spreadsheetDetails.getData()


            let showInvalidInputMessage = false;
            table.forEach((row, i) => {
                if(!this.confirmInputOfRow(row)){
                    //dye invalid inputs red
                    spreadsheetDetails.records[i].forEach((cell) => cell.style.backgroundColor = 'rgba(255, 0, 0, 0.5)');
                    showInvalidInputMessage = true;
                }else{
                    // remove dying if there was any
                    spreadsheetDetails.records[i].forEach((cell) => cell.style.backgroundColor = 'white');

                    
                    // sync with backend


                }
            });
            if(showInvalidInputMessage){
                this.$swal({ icon: 'error', title: 'Achtung', text: 'Mindestens eine Zeile hat einen ungültigen Eintrag'})
            }else{
                this.$socket.emit('edit-project-evaluation-details', { token: this.token, project: this.project, table: table.map(e => { return {id: e[0], date: e[1], name: e[2], department: e[3], hours: e[4], addendum: e[5], comment: e[6]}})})
            }

        },
        confirmInputOfRow(row){
            const date = this.$moment(row[1])
            // check if date is the correct format
            if(!date.isValid())
                return false;

            // comboboxes cant be empty
            if(row[1] == '' || row[2] == '')
                return false;

            // check time
            if(!this.isValidTimeFormat(row[4]))
                return false

            return true
        },
        isValidTimeFormat(timeString) {
            // Regular expression to match the format HH:mm:ss
            var timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;

            // Check if the time string matches the expected format
            if (!timeFormatRegex.test(timeString)) {
                return false; // Format does not match
            }

            // Extract hours, minutes, and seconds
            var [hours, minutes, seconds] = timeString.split(':').map(Number);

            // Check if hours, minutes, and seconds are within valid ranges
            if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59 || seconds < 0 || seconds > 59) {
                return false; // Invalid time values
            }

            return true; // Time format and values are valid
        }


    },
    sockets: {
        'set-project-evaluation-details': function ([{ name, nr, created, hours, projectRuntime, projectStartEndTime }, hoursPerson, hoursDepartment, hoursDetails]){
            this.projectname = name;
            this.projectnr = nr;
            this.projectCreated = this.$moment(created).format('YYYY-MM-DD')
            this.projectHours = hours
            this.projectRuntime = projectRuntime
            this.projectStartEndTime = projectStartEndTime

            this.$nextTick(() => {
                spreadsheetPersonen.setData(hoursPerson.map(e => [e.name.replace('.', ' '), e.hours]))
                const dataPersons = GoogleCharts.api.visualization.arrayToDataTable([
                    ['Arbeit', 'seconds', {role: 'tooltip', title: 'Arbeit'}],
                    ...hoursPerson.map(e => [e.name.replace('.', ' '), this.timeToSeconds(e.hours), `
                    ${e.name.replace('.', ' ')}\n${(this.timeToSeconds(e.hours) / this.timeToSeconds(hours) * 100).toFixed(2)}%\n ${e.hours}`])
                    ]);

                var options = {
                    height: 400,
                    width: 600,

                };
                if(!this.$refs['pie-chart'])
                    return

                var chartPersons = new GoogleCharts.api.visualization.PieChart(this.$refs['pie-chart']);
                chartPersons.draw(dataPersons, options);

                //Data fuer Auswerteungen Abteilungen
                spreadsheetDepartments.setData(hoursDepartment.map(e => [e.name, e.hours]))
                const dataDepartments = GoogleCharts.api.visualization.arrayToDataTable([
                    ['Arbeit', 'seconds', {role: 'tooltip', title: 'Arbeit'}],
                    ...hoursDepartment.map(e => [e.name, this.timeToSeconds(e.hours), `${e.name}\n${(this.timeToSeconds(e.hours) / this.timeToSeconds(hours) * 100).toFixed(2)}%\n ${e.hours}`])
                ]);
                const chartDepartments = new GoogleCharts.api.visualization.PieChart(this.$refs['pie-chart-department']);
                chartDepartments.draw(dataDepartments, options);

                spreadsheetDetails.setData(hoursDetails.map(e => [e.id, this.$moment(e.date).format('YYYY-MM-DD'), e.name, e.department, e.hours, e.addendum, e.comment]))
                

                this.changeDetected = false
            });
          
        },

        'feedback-project-evaluation-details': function(success){

            if(success){
                //this.changeDetected = false
                this.$socket.emit('get-project-evaluation-details', {token: this.$gettoken(), project:this.project})
            }
        }
    },
    watch:{
        showDialog(){
            if(!this.showDialog)
                return

           
        }    
    },
    mounted: function() {
        GoogleCharts.load(() => {});

        if(!this.$refs["times-per-person-table"]){
            return
        }

    },
    computed:{
        token() {
            return this.$store.getters.token;
        },
    },
    data(){
        return{
            clicked: false,
            options: {
                data:[[ '', '00:00']],
                columns:[
                     
                    { title:'Name', width:170 },

                    { title:'Stunden', width:100 },
                ]
            },

            showDialog: false,

            projectnr: '007',
            projectname: '',

            projectCreated: this.$moment().format('YYYY-MM-DD'),
            projectHours: '0',

            projectRuntime: '',
            projectStartEndTime: '',

            hoursPerson: [],


            sectionSelected: 'persons',
            pdfgenerating: false,

            changeDetected: false,
        }
    }
}
</script>
<style>
div.project-header h4{
    margin: 2.6pt;
}
</style>