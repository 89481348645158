<template>
    <div class="user-times vertical-wrapper">
        <h2>Zeit Konto</h2>
        <md-button v-on:click="open_dialog" class="md-primary" :class="{'hide':!$getadmin()}">User Settings</md-button>
        <month-closed-tag :locked="locked"></month-closed-tag>

        <text-with-value text="Sollstunden:" :editable="isadmin >= 1" :value="target_hours.toString()" unit="h"
            v-on:value-changed="valueChanged"></text-with-value>
        <text-with-value text="Iststunden:" :value="is_hours.toString()" unit="h"></text-with-value>
        <div style="flex: 0 0 20pt"></div>
        <text-with-value text="Überstunden Monat:" :value="overtime_this_month.toString()" unit="h"></text-with-value>
        <text-with-value text="Überstunden Gesamt:" :value="overtime.toString()" unit="h"></text-with-value>
        <div style="flex: 0 0 20pt"></div>
        <text-with-value :class="{'hide':minijob}" text="Urlaub noch offen:" :value="vacation_days.toString()"
            unit="Tage"></text-with-value>

        <add-user :show="showSettings" :user="{id:id, name:name}" v-on:close-user-add="closeUserAdd" v-on:close="closeUserAdd"></add-user>

        <holidays :show="showHolidays" v-on:close-holidays="showHolidays = false" :class="{'hide':!$getadmin()}">
        </holidays>
        <md-button v-on:click="showHolidays = true" class="md-primary" :class="{'hide':!$getadmin()}">Feiertage
        </md-button>

    </div>
</template>
<script>

import addUserVue from '../components/add-user.vue'
import Holidays from '../components/holidays.vue'
import MonthClosedTag from '../components/month-closed-tag.vue'
import textWithValueVue from '../components/text-with-value.vue'


export default {
    name: 'user-times',
    props: ['id', 'name'],
    components:{
        'text-with-value':textWithValueVue,
        'add-user':addUserVue,
        'holidays':Holidays,
        MonthClosedTag
    },
    computed: {
        isadmin(){
            return this.$store.getters.isadmin
        }
    },
    methods:{
        open_dialog(){
            this.showSettings = true
        },
        closeUserAdd(){
            this.showSettings = false
        }, 
        valueChanged(value){
            this.target_hours = value
            this.$socket.emit('set-payment-of-minijob', {token: this.$gettoken(), user:{ id:this.$props.id, }, date:this.$getdate(), hours:this.target_hours })
        }

    },
    data(){
        return{
            target_hours: 40,
            is_hours: 35,
            overtime_this_month: -5,
            overtime: 20,
            showSettings: false,
            showHolidays: false,
            vacation_days: 0,
            locked: false,
            minijob:false

        }
    },beforeRouteEnter (to, from, next) { 
        next(vm => { 
            vm.$store.commit('set-show-date', true)

            if(from.path == '/')
                vm.$socket.emit('get-users', {token:vm.$gettoken()})
            if(vm.$props.id){
                vm.$socket.emit('get-user-times', {token: vm.$gettoken(), user:{ id:vm.$props.id, }, date:vm.$getdate() })
            }
            next();
        }) 
    },
    sockets:{
       'set-user-times': function({overtime, overtime_this_month, target_hours, is_hours, vacation_days, locked, minijob}){
           this.overtime_this_month = overtime_this_month;
           this.overtime = overtime;
           this.target_hours = target_hours;
           this.is_hours = is_hours;
           this.vacation_days = vacation_days;
           this.locked = locked? true: false;
           this.minijob = minijob.minijob? true: false;
       }
    }
}
</script>
<style>
.user-times{
    margin: 5pt 20pt;
}
</style>