<template>
    <div>
        <p>You are getting Logged in...</p>
    </div>
</template>
<script>
export default {
    name: 'login-via-url',
    props: ['url'],
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            vm.$socket.emit('login-via-url', {url: vm.$props.url})
            next();
        }) 
    },
}
</script>