<template>
    <div class="vertical-wrapper">
        <h2>Abschluss</h2>
            <div class="horizontal-wrapper" style="flex: 0 0 auto;">
                <div class="placeholder"></div>
                <div class="placeholder"></div>

                <md-button class="md-primary" v-on:click="user_selection"> Auswahl</md-button>
                <div class="placeholder"></div>
                <md-button class="md-primary" v-on:click="export_file"> Exportieren</md-button>
                <div class="placeholder"></div>
                <md-button class="md-primary" v-on:click="send_file"> Absenden</md-button>
                <div class="placeholder"></div>
                <div class="placeholder"></div>

            </div>
            <div ref="times-table">

            </div>


            <md-dialog :md-active.sync="showDialog">
                <div class="horizontal-wrapper" style="flex: 0 0 auto;">
                    <md-dialog-title style="display: inline">Benutzer Auswahl</md-dialog-title>
                    
                    <md-dialog-actions>
                        <md-button class="md-primary" @click="showDialog = false; ">Close</md-button>
                        <md-button class="md-primary" @click="save_selection">Save</md-button>
                    </md-dialog-actions>
                </div>
                <div style="padding: 5pt; flex: 1 0 0;">
                    <selection-list :items="user" :top-selection-all="true" :search-bar="true"></selection-list>

                </div>
            
            </md-dialog>

            <div class="horizontal-wrapper">
                <div></div>
                    <vue-html2pdf :html-to-pdf-options="{
                                        showLayout: false,
                                        floatLayout: false,
                                        enableDownload: true,
                                        previewModal: false,
                                        paginateElementsByHeight: 1400,
                                        margin: 5,
                                        filename: 'Stunden-' +  this.$moment(this.$getdate()).format('MMMM-YYYY') + '.pdf',
                                        pdfQuality: 2,
                                    
                                        jsPDF: {
                                            unit: 'mm',
                                            format: 'a4',
                                            orientation: 'landscape'
                                        }
                                    }" :show-layout="false" :float-layout="false" :enable-download="true" :preview-modal="false"
                        :paginate-elements-by-height="1400"
                        :filename="'Stunden-' +  this.$moment(this.$getdate()).format('MMMM-YYYY') + '.pdf'" :pdf-quality="2" :margin="5"
                        pdf-format="a4" pdf-orientation="landscape" pdf-content-width="1000px" @progress="onProgress($event)"
                        @hasStartedGeneration="hasStartedGeneration()" v-on:hasDownloaded="generatePFD = false" ref="html2Pdf">
                        <section slot="pdf-content" class="pdf-content" :class="{'hide':!generatePFD}">
                            <div class="horizontal-wrapper" style="width: 1000px">
                                <div class="vertical-wrapper">
                                    <h4>Dokumentation der</h4>
                                    <h4 style="marign-top: 0">Arbeitszeiten</h4>
                                </div>
                    
                                <div class="vertical-wrapper">
                                    <h4>Monat/Jahr:</h4>
                                </div>
                                <div class="placeholder"></div>
                                <div class="vertical-wrapper">
                                    <h4>{{this.$moment(this.$getdate()).format('MMMM YYYY') }}</h4>
                                </div>
                                <div class="logo icon" style="flex: 0 0 100pt; ">
                    
                                </div>
                    
                            </div>
                            <div ref="pdf-table" class="times-table">
                    
                            </div>
                    
                        </section>
                    
                    </vue-html2pdf>
                <div></div>
            </div>
       
    </div>
</template>
<script>
import jexcelStyle from "jexcel/dist/jexcel.css"; // eslint-disable-line no-unused-vars
import jexcel from "jexcel"; // eslint-disable-line no-unused-vars
import selectionListVue from '../../components/VueComponents/selection-list.vue';
import VueHtml2pdf from 'vue-html2pdf'



export default {
    name: 'user-files-hours',
    components:{
        'selection-list':selectionListVue,
        VueHtml2pdf
    },
    methods:{
        onProgress(){

        },
        user_selection(){
            this.showDialog = true
        },
        export_file(){
            this.generatePFD = true
            this.$refs['pdf-table'].innerHTML = ''
            const temp_table = jexcel(this.$refs['pdf-table'],{
                    data:[[ '08:00', '08:05', '08:04', '08:05',  ]],
                 columns:[
                    { title:'Name', width:130 },

                    { title:'Sollzeit', width:90 },
                    { title:'Istzeit', width:90 },
                    { title:'Überstunden', width:100 },
                    { title:'Überstunden Gesamt', width:150 },
                    
                    { title:'Urlaub genommen', width:150 },
                    
                    { title:'Rest Urlaub', width:120 },
                ]   
                });
            
            temp_table.setData(this.table)
            temp_table.hideIndex()

            this.$refs.html2Pdf.generatePdf()
        },
        send_file(){

        },
        save_selection(){
            this.showDialog = false
            this.$cookies.set('user-selection', JSON.stringify(this.user) )
            this.$socket.emit('get-user-accounts', {token: this.$gettoken(), date:this.$getdate(), selection: this.user})

        },
        hasGenerated(){
            this.generatePFD = false
        },
    },
    sockets:{
        'set-user-accounts-users':function({user}){
            let seletion_saved = this.$cookies.get('user-selection')

            if(!seletion_saved){
                seletion_saved = []
                this.$cookies.set('user-selection', JSON.stringify(seletion_saved) )
            }else{
                seletion_saved = JSON.parse(seletion_saved)
            }
            user.forEach(e => e.active= false)
            this.user = user
            this.user.forEach(u => {
                const m = seletion_saved.find(e => e.id == u.id)
                if(!m)
                    return
                u.active = m.active
            }); 
        },
        'set-user-accounts-table':function({table}){
            this.table = table
            this.spreadsheet.setData(table)
        }
    },
    watch:{
        date(){
            let seletion_saved = JSON.parse(this.$cookies.get('user-selection'))
            if (!seletion_saved)
                seletion_saved = []
            this.$socket.emit('get-user-accounts', { token: this.$gettoken(), date: this.date, selection: seletion_saved })

        }
    },
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            if(from.path == '/'){
                vm.$socket.emit('get-users', {token:vm.$gettoken()})
                return
            }
            
            let seletion_saved = JSON.parse(vm.$cookies.get('user-selection'))
            if(!seletion_saved)
                seletion_saved = []
            vm.$socket.emit('get-user-accounts', {token: vm.$gettoken(), date:vm.date, selection: seletion_saved})

            next();
        }) 
    },
    mounted: function() {
        this.spreadsheet = jexcel(this.$refs["times-table"], this.options);
        Object.assign(this, this.spreadsheet);
    },
    computed:{
        isadmin(){
            return this.$store.getters.isadmin
        },
        date(){
            return this.$store.getters.getDate
        }
    },
    data(){
        return{
            table: [],
            spreadsheet: null,
            generatePFD: false,
            showDialog: false,
            options:{
                data:[[ '08:00', '08:05', '08:04', '08:05',  ]],
                 columns:[
                     
                    { title:'Name', width:130 },

                    { title:'Sollzeit', width:100 },
                    { title:'Istzeit', width:100 },
                    { title:'Überstunden', width:100 },
                    { title:'Überstunden Gesamt', width:150 },
                    
                    { title:'Urlaub', width:100 },
                    
                    { title:'Rest Urlaubstage', width:150 },


                ]   
            },
            user: [

            ]
        }
    }
}
</script>