<template>
    <div>
        <md-dialog :md-active="open" class="" v-on:md-closed="$emit('close')" v-on:md-clicked-outside="$emit('close')" style="z-index: 20">
            <div class="horizontal-wrapper" style="flex: 0 0 auto;">
                <md-dialog-title style="display: inline">Projekt</md-dialog-title>
                
                <md-dialog-actions>
                    <md-button class="md-primary" @click="$emit('close') ">Close</md-button>
                    <md-button class="md-primary" @click="save_project">Save</md-button>
                </md-dialog-actions>
            </div>
            <div class="vertical-wrapper project-editor-content" style="padding: 25px;">
                <div class="vertical-wrapper" style="width: auto;">
                    <md-field style="flex: 0 0 auto; height: auto; ">
                        <label>Projektnummer:</label>
                        <md-input v-model="projectnr" ></md-input>
                    </md-field>
                    <div style="flex: 0 0 5pt"></div>
                    <md-field style="flex: 0 0 auto; height: auto;  ">
                        <label>Projektbezeichnung:</label>
                        <md-input v-model="projectname" ></md-input>
                    </md-field> 
                </div> 
                <div>
                    <md-switch v-model="active">Aktiv</md-switch>
                </div>
                <div>
                    <md-field style="flex: 0 0 auto; ">
                        <label>Kommentar:</label>
                        <md-textarea v-model="comment" ></md-textarea>
                    </md-field> 
                </div>
                <div class="horizontal-wrapper">
                    <div></div>
                    <md-button class="md-primary md-raised" v-on:click="deleteProject">Löschen</md-button>
                    <div></div>
                </div>
            </div>
        </md-dialog>
    </div>
</template>
<script>
export default {
    name: 'project-editor-details',
    props: { open:Boolean },
    methods:{ 
        save_project(){
            this.$socket.emit('edit-project', {token: this.$gettoken(), project: {id: this.projectid, name: this.projectname, nr:this.projectnr, comment:this.comment, active: this.active}})
            this.$emit('close')
        },
        deleteProject(){
            this.$swal({
                icon:'question',
                title: 'Projekt löschen?',
                text: 'Projekt und alle Verbindungen unwiderruflich löschen?',
                showDenyButton:true,
                showOkButton:true,
                denyButtonText: 'nein',
                okButtonText: 'Ja',

            }).then((e)=> {
                if(!e.isConfirmed)
                    return
                this.$socket.emit('delete-project', {token: this.$gettoken(), project:{id: this.projectid}})
                this.$emit('close')
            }) 
        }
    },
    watch:{
        open(){
            if(this.open){
                this.projectid = -1
                this.projectnr = ''
                this.projectname = ''
                this.comment = ''
                this.active = false

            }
        }
    },  
    sockets:{
        'set-project-details':function({id, projectnr, name, comment, active}){
            this.projectid = id
            this.projectnr = projectnr
            this.projectname = name
            this.comment = comment
            this.active = active? true: false

        }
    },
    data(){
        return{
            showDialog: false,
            
            projectid: -1,
            projectnr: '',
            projectname: '',
            comment: '',
            active: false

        }
    }
}
</script>
<style>
.md-switch.md-theme-default.md-checked .md-switch-thumb{
    background-color: var(--color2) !important;

}
.project-editor-content{
    min-width: 400pt;
}
@media screen and (max-device-width: 800px) {
    .project-editor-content{
        min-width: auto;
    }

}

</style>