import VueRouter from 'vue-router'

import loginView from '@/views/login-view.vue'
import loginViaUrlView from '@/components/login-via-url.vue'

import user from '@/views/user.vue'

import userBuchung from '@/views/user-buchung.vue'

import userProjects from '@/views/user-projects.vue'
import userProjectsTransactionsVue from '@/views/user-projects/user-projects-transactions.vue'
import userProjectsListVue from '@/views/user-projects/user-projects-list.vue'


import userEvaluation from '@/views/user-evaluation.vue'
import userTimes from '@/views/user-times.vue'

import userAccess from '@/views/user-accesses.vue'

import userFiles from '@/views/user-files.vue'
import userFilesHours from '@/views/user-files/user-files-hours.vue'
import userFilesSick from '@/views/user-files/user-files-sick.vue'

import userFilesHoursDiagram from '@/views/user-files/user-files-hours-diagram.vue'
import userFilesProjects from '@/views/user-files/user-files-projects.vue'


import userCalculations from '@/views/user-calculations.vue'

export default new VueRouter({
    mode: 'history',
    routes: [
        { path: '/', redirect: {name: 'user'}},
        { path: '/login', name: 'login', component:loginView},
        { path: '/login-via-url/:url', name: 'login-via-url', props: true, component: loginViaUrlView },
        {
            name: 'user',
            path: '/user/:id/:name',
            component: user,
            props: true,
            children: [
                {
                    path: 'buchung',
                    props: true,
                    component: userBuchung,
                   
                }, 
                {
                    path: 'projects',
                    props: true,
                    component: userProjects,
                    redirect: { name: 'user-projects-transactions' },
                    children:[
                        {
                            name: 'user-projects-transactions',
                            path: 'projects-transactions',
                            component: userProjectsTransactionsVue
                        },
                        {
                            name: 'user-projects-list',
                            path: 'project-list',
                            component: userProjectsListVue
                        }
                    ]
                },
                {
                    path: 'evaluation',
                    props: true,
                    component: userEvaluation
                },
                {
                    path: 'times',
                    props: true,
                    component: userTimes
                },
                {
                    path: 'access',
                    props: true,
                    component: userAccess
                }, {
                    path: 'files',
                    props: true,
                    component: userFiles,
                    redirect: { name: 'user-files-hours' },
                    children: [
                        {
                            name: 'user-files-hours',
                            path: 'files-hours',
                            component: userFilesHours
                        },{
                            name: 'user-files-sick',
                            path: 'files-sick',
                            component: userFilesSick
                        },
                        {
                            name: 'user-files-hours-diagram',
                            path: 'files-hours-diagram',
                            component: userFilesHoursDiagram
                        },
                        {
                            name: 'user-files-projects',
                            path: 'files-projects',
                            component: userFilesProjects
                        },
                        
                    ]   
                }, {
                    path: 'calculations',
                    props: true,
                    component: userCalculations,
                   
                }
            ]
        },

        { path: '*', template: '<p>404</P>' } // Not found
    ]
})