<template>
      <div class="holiday-dialog">
        <md-dialog :md-active.sync="showDialog" style="" class="holiday-dialog">
            <div class="horizontal-wrapper" style="flex: 0 0 auto;">
                <md-dialog-title style="display: inline">Feiertage</md-dialog-title>
                
                <md-dialog-actions>
                    <md-button class="md-primary" @click="$emit('close-holidays')">Close</md-button>
                    <md-button class="md-primary" @click="save_holidays">Save</md-button>
                </md-dialog-actions>
            </div>


            <div class="vertical-wrapper" style="margin: 20px 30px: flex: 1 0 300pt;">
                 <md-field style="flex: 0 0 auto;">
                    <label>Jahr:</label>
                    <md-input v-model="year" v-on:change="change_date"></md-input>
                </md-field>

                <div ref="holidays"></div>
                <md-button class="md-primary" v-on:click="holidays_spreadsheat.insertRow(['', ''])">hinzufügen</md-button>
            </div>
         
           
        </md-dialog>
    </div>
</template>
<script>
import jexcelStyle from "jexcel/dist/jexcel.css"; // eslint-disable-line no-unused-vars
import jexcel from "jexcel"; // eslint-disable-line no-unused-vars


export default {
    name: 'holidays',
    props: {'show':Boolean},
    watch:{
        show(){
            this.showDialog = this.$props.show

            if(this.$props.show)
                this.$socket.emit('get-holidays', {token:this.$gettoken(), year:this.year})

        },
        showDialog(){
            if(!this.showDialog)
                this.$emit('close-holidays')
        }
    },
    methods:{
        'save_holidays':function(){

            const inValidDate = this.holidays_spreadsheat.getData().reduce((arr, e) => this.$moment(e[0]).isValid() && arr, true )
            if(!inValidDate){
                 this.$swal({
                    icon:'error',

                    title:'Fehler',
                    text: 'Mindestens ein Eintrag enhält ein ungültiges Datum' ,
                })

                return
            }


            this.$swal({
                icon:'warning',

                title:'Sind Sie Sicher?',
                text: 'Möchten Sie die Feiertage wirklich bearbeiten' ,
                showDenyButton:true,
                confirmButtonText:'ja',
                denyButtonText: 'nein'
            }).then(() => {
                this.$socket.emit('save-holidays', {token:this.$gettoken(), year:this.year, holidays: this.holidays_spreadsheat.getData()})
                this.$emit('close-holidays', '')
                delete this.holidays_spreadsheat
            })
        }, change_date(){
           this.$socket.emit('get-holidays', {token:this.$gettoken(), year:this.year})

        }
    },
    data(){
        return{
            showDialog: this.$props.show,
            year: this.$moment().format('YYYY'),
            holidays: [],
            options:{
                data:[[ '', '',  ]],
                 columns:[
                    { title:'Datum', width:300, },

                    { title:'Name', width:550 },


                ]   
            }
        }
    },
    updated(){
        console.log('load spreadsheet')
        if(this.show && !this.holidays_spreadsheat){
            this.$refs["holidays"].innerHTML = ''
            this.holidays_spreadsheat = jexcel(this.$refs["holidays"], this.options);
            Object.assign(this, this.holidays_spreadsheat);
        }
    }  ,
 
    sockets:{
        'set-holidays':function({holidays}){
            this.$refs["holidays"].innerHTML = ''

            this.holidays_spreadsheat = jexcel(this.$refs["holidays"], this.options);
            Object.assign(this, this.holidays_spreadsheat);

            if(holidays.length)
                this.holidays_spreadsheat.setData(holidays)
            else
                this.holidays_spreadsheat.setData([['', '']])

        }
    },
   
}
</script>
<style>
.holiday-dialog .md-dialog-container{
  padding: 25pt;
  overflow-y: scroll;
} 
</style>