<template>
    <div>
        <div v-ripple class="horizontal-wrapper project md-primary md-theme-default" :class="{'clicked': clicked}" v-on:click="project_clicked" style="cursor:pointer; font-size: 11pt;">
           
            <h4 class="full-opacity">{{name}}</h4>
            <div class="placeholder"></div>
            <h4 class="full-opacity">{{hours + 'h'}}</h4>
        </div>
        <md-dialog :md-active.sync="showDialog" >
            <div style="min-height: 400pt">

                <div class="horizontal-wrapper" style="flex: 0 0 auto;">
                    <md-dialog-title style="display: inline">Projekt</md-dialog-title>
                    
                    <md-dialog-actions>
                        <md-button class="md-primary" @click="showDialog = false; $emit('project-close')">Close</md-button>
                        <md-button class="md-primary" @click="save_project">Save</md-button>
                    </md-dialog-actions>
                </div>
                
                <div class="vertical-wrapper" style="padding: 20px 30px; ">
                    <md-field style="flex: 0 0 auto;">
                            <label>Projektnummer:</label>
                            <md-input v-model="project_nr" ></md-input>
                    </md-field>
                    <div style="overflow-y:scroll; flex: 0 0 auto; max-height: 70vh">
                        <h3 v-ripple v-for="p in projects.filter(e => e.name.includes(project_nr))" :key="p.id" v-on:click="change_project(p)" style="cursor:pointer">{{p.name}}</h3>
                    </div>
                
                    <div class="vertical-wrapper" >
                        <h2 class="header">Arbeit</h2>
                        <datetime type="time" title="Arbeitszeit am Projekt" v-model="time"></datetime>
                    </div>
                    <md-field>
                        <label for="transaction">Abteilung</label>
                        <md-select v-model="project_department" name="transaction" >
                            <md-option v-for="d in departments" :key="d.key" :value="d.id">{{d.name}}</md-option>
                            <md-option value="null">-</md-option>


                        </md-select>
                    </md-field>
                    <div>
                        <md-switch class="md-primary" v-model="addendumValue">Nachtrag</md-switch>
                    </div>

                     <md-field style="flex: 0 0 auto;">
                            <label>Kommentar</label>
                            <md-textarea v-model="comment" ></md-textarea>
                    </md-field>
                    <div class="horizontal-wrapper">
                        <div></div>
                        <md-button v-on:click="deleteProjectEntry" style="justify-content: center;" class="md-raised md-primary">Löschen</md-button>
                        <div></div>

                    </div>

                </div>
            </div>
           
        </md-dialog>
    </div>
</template>
<script>
export default {
  components: {  },
    name:'project',
    props: { id: Number, project_id: Number, name: String, date:String, hours: String, comment: String, idu_department: Number, projects: Array, addendum:Boolean },
    methods: { 
        project_clicked(){
            this.showDialog = true
            this.$socket.emit('get-departments', {token: this.token, user: this.user})
        },
        change_project(p){
            this.projects_id = p.id
            this.project_nr = p.name
        },
        save_project(){
            this.$emit('project-save', { id: this.$props.id, project_id: this.projects_id, name: this.project_nr, time: this.$moment(this.time).format('HH:mm'), comment: this.comment, addendum: this.addendumValue, IDu_department:this.project_department})
            this.showDialog = false
        },
        async deleteProjectEntry(){
            const {isConfirmed} = await this.$swal.fire({
                title: 'Sicher entfernen?', 
                icon: 'question', 
                text: 'Projekt Eintrag entfernen?', 
                showDenyButton: true, denyButtonText: 'Nein',
                showConfirmButton: true, confirmButtonText: 'Ja'

            })
            if(isConfirmed){
                this.$emit('delete-project')
                this.showDialog = false
            }
        }
    },
    computed:{
        isadmin(){
            return this.$store.getters.isadmin
        },
        user(){
            return this.$store.getters.getUser
        },
        token() {
            return this.$store.getters.token;
        },
        departments(){
            return this.$store.getters.getDepartments
        }
    },
    data(){
        return{
            projects_id: this.$props.project_id,
            clicked: false,
            showDialog: this.$props.id < 0,
            time: this.$props.hours,
            project_nr: this.$props.name,
            project_department: this.$props.idu_department,
            addendumValue: this.$props.addendum
        }
    }
}
</script>
<style>
.project {
    margin: 10px 2.5%;
    overflow: visible;

    padding: 0 10px;   
}

.full-opacity{
}
.project .vdatetime{
    flex: 1 0 0;
}
.project .vdatetime-input{
        width: 100%;
}
</style>