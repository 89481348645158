var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-evaluation"},[_c('div',{staticClass:"horizontal-wrapper"},[_c('div',{staticClass:"placeholder"}),_c('h2',[_vm._v("Stundenzettel")]),_c('div',{staticClass:"placeholder"}),_c('md-button',{staticClass:"md-primary",on:{"click":_vm.exporting}},[_vm._v("Exportieren")]),_c('div',{staticClass:"placeholder"}),_c('md-button',{staticClass:"md-primary",class:{'hide':!_vm.$getadmin()},on:{"click":_vm.abschluss}},[_vm._v("Abschließen")]),_c('div',{staticClass:"placeholder"})],1),_c('month-closed-tag',{attrs:{"locked":_vm.locked}}),_c('div',{ref:"spreadsheet"}),_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"html-to-pdf-options":{
            showLayout: false,
            floatLayout: false,
            enableDownload: true,
            previewModal: false,
            paginateElementsByHeight: 1400,
            margin: 5,
            filename: _vm.name.replace('.', '-') + '-' +  this.$moment(this.$getdate()).format('MMMM-YYYY') + '.pdf',
            pdfQuality: 2,
          
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'landscape'
            }
        },"show-layout":false,"float-layout":false,"enable-download":true,"preview-modal":false,"paginate-elements-by-height":1400,"filename":_vm.name.replace('.', '-') + '-' +  this.$moment(this.$getdate()).format('MMMM-YYYY') + '.pdf',"pdf-quality":2,"margin":5,"pdf-format":"a4","pdf-orientation":"landscape","pdf-content-width":"1000px"},on:{"progress":function($event){return _vm.onProgress($event)},"hasStartedGeneration":function($event){return _vm.hasStartedGeneration()},"hasDownloaded":function($event){_vm.generatePFD = false}}},[_c('section',{staticClass:"pdf-content",class:{'hide':!_vm.generatePFD},attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('div',{staticClass:"horizontal-wrapper",staticStyle:{"width":"1200px"}},[_c('div',{staticClass:"vertical-wrapper"},[_c('h4',[_vm._v("Dokumentation der")]),_c('h4',{staticStyle:{"marign-top":"0"}},[_vm._v("täglichen Arbeitszeiten")])]),_c('div',{staticClass:"vertical-wrapper"},[_c('h4',[_vm._v("Name:")]),_c('h4',[_vm._v("Monat/Jahr:")])]),_c('div',{staticClass:"placeholder"}),_c('div',{staticClass:"vertical-wrapper"},[_c('h4',[_vm._v(_vm._s(_vm.name.replace('.', ' ')))]),_c('h4',[_vm._v(_vm._s(this.$moment(this.$getdate()).format('MMMM YYYY')))])]),_c('div',{staticClass:"logo icon",staticStyle:{"flex":"0 0 100pt"}})]),_c('div',{ref:"times-table",staticClass:"times-table"}),_c('div',{staticClass:"horizontal-wrapper"},[_c('div',[_c('h4',[_vm._v("Summe:")])]),_c('div',[_c('h4',[_vm._v(_vm._s(_vm.workSum + ' h'))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }