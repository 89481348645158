<template>
        <div class="user vertical-wrapper">
            <div class="vertical-wrapper" style="flex: 0 0 auto" :class="{'hide': !showDate}">
                <h2>Datum</h2>
                <div class="horizontal-wrapper" style="flex: 0 0 auto; margin: 5pt">
                    <div></div>
                    <div class="icon"
                        :style="`flex: 0 0 16pt; margin-right: 5pt; margin-left: 20pt; background-image: url(${require('../assets/controls/angle-left-solid.svg')})`"
                        v-on:click="addToDate(-1)">
                    </div>
                    <h2 style="margin: 0 15pt; font-size: 15pt; line-height: 20pt;" v-on:click="e => $refs['date']? $refs['date'].open(e): 0">{{ $moment(day).format('YYYY.MMM.DD') }}</h2>
                    <datetime type="date" class="datetime hide-input" ref="date" title="Tag" :value="day" v-model="day" ></datetime>
                    <div class="icon"
                        :style="`flex: 0 0 16pt; margin-left: 5pt; margin-right: 20pt; background-image: url(${require('../assets/controls/angle-right-solid.svg')})`"
                        v-on:click="addToDate(1)">
                    </div>
                    <div></div>

                </div>

            </div>
            <div class="vertical-wrapper" style="flex: 1 0 0; overflow:scroll; ">

                <router-view ></router-view>
            </div>
           

            <md-bottom-bar style="flex: 0 0 auto; z-index:10" class="footer" md-type="shift"  :md-sync-route="true" v-on:md-changed="change_bar" :options="{}"  >
                <md-bottom-bar-item id="buchung" :to="`/user/${this.id}/${this.name}/buchung`" md-label="Buchungen" :md-icon="require('../assets/user-clock-solid.svg')" v-on:click="reload_route" ></md-bottom-bar-item>
                <md-bottom-bar-item v-if="showProjects" id="projects" :to="`/user/${this.id}/${this.name}/projects`" md-label="Projekte" :md-icon="require('../assets/hard-hat-solid.svg')"  v-on:click="reload_route" ></md-bottom-bar-item>

                <md-bottom-bar-item id="evaluation" :to="`/user/${this.id}/${this.name}/evaluation`" md-label="Auswertung" :md-icon="require('../assets/calendar-alt-solid.svg')"  v-on:click="reload_route" ></md-bottom-bar-item>
                <md-bottom-bar-item id="times" :to="`/user/${this.id}/${this.name}/times`" md-label="Stunden" :md-icon="require('../assets/user-circle-solid.svg')"  v-on:click="reload_route" ></md-bottom-bar-item>

                <md-bottom-bar-item v-if="showAccess" id="access" :to="`/user/${this.id}/${this.name}/access`" md-label="Passwörter" :md-icon="require('../assets/key-solid.svg')"  v-on:click="reload_route" ></md-bottom-bar-item>
                <md-bottom-bar-item v-if="showCalculations" id="calculations" :to="`/user/${this.id}/${this.name}/calculations`" md-label="Formeln" :md-icon="require('../assets/square-root-variable-solid.svg')"  v-on:click="reload_route" ></md-bottom-bar-item>
                
                <md-bottom-bar-item v-if="$getadmin()" id="files" :to="`/user/${this.id}/${this.name}/files`" md-label="Abschluss" :md-icon="require('../assets/file-alt-solid.svg')"  v-on:click="reload_route" ></md-bottom-bar-item>
            </md-bottom-bar>
        </div>
</template>
<script>
export default {
    name: 'user',
    props: ['id', 'name'],
    watch:{
        $route (to){
              const r = to.path.split('/')
            this.route = r[r.length-1]
        },

        day(){
            this.$store.commit('set-date', this.day)

            this.$setdate(this.day)
            const r = this.$router.currentRoute.path.split('/')
            this.route = r[r.length-1]
            if(this.route == 'buchung')
                this.$socket.emit('get-user-transaction', {token: this.$gettoken(), user:{ id:this.$props.id, }, date:this.day })
            else if (this.route == 'projects')
                this.$socket.emit('get-user-projects', {token: this.$gettoken(), user:{ id:this.$props.id, }, date:this.day })
            else if (this.route == 'evaluation')
                this.$socket.emit('get-user-evaluation', {token: this.$gettoken(), user:{ id:this.$props.id, }, date:this.day})
            else if (this.route == 'times')
                this.$socket.emit('get-user-times', {token: this.$gettoken(), user:{ id:this.$props.id, }, date:this.day})
            else if (this.route == 'files'){
                let seletion_saved = JSON.parse(this.$cookies.get('user-selection'))
                if(!seletion_saved)
                    seletion_saved = []
                this.$socket.emit('get-user-accounts', {token: this.$gettoken(), date:this.$getdate(), selection: seletion_saved})
            }
        },

      
    },
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            if(vm.$props.id){
                console.log('load user')
            }else if (vm.$props.id==0){
                console.log('load user')
                
            }

            next();
        }) 
    },
    methods:{
        change_date(){
            this.$setdate(this.day)
            const r = this.$router.currentRoute.path.split('/')
            this.route = r[r.length-1]
            if(this.route == 'buchung')
                this.$socket.emit('get-user-transaction', {token: this.$gettoken(), user:{ id:this.$props.id, }, date:this.day })
            else if (this.route == 'evaluation')
                this.$socket.emit('get-user-evaluation', {token: this.$gettoken(), user:{ id:this.$props.id, }, date:this.day})
            else if (this.route == 'times')
                this.$socket.emit('get-user-times', {token: this.$gettoken(), user:{ id:this.$props.id, }, date:this.day})
        },
        change_bar(id){
            this.route = id
            //if(['buchung', 'projects', 'evaluation', 'times'].some( e => this.$router.currentRoute.path.includes(e)) )
            //    this.$router.push( id ).catch(() => {})
            
            //else
              //  this.$router.push({path: this.$router.currentRoute.path +  '/' + id}).catch(() => {})
        },
        reload_route(){
            const r = this.$router.currentRoute.path.split('/')
            this.route = r[r.length-1]
            
        },
        addToDate(number){
            if(this.route == 'buchung' || this.route == 'projects-transactions' || this.route == 'projects-list')
                this.day = this.$moment(this.day).add(number, 'days').toISOString()
            else
                this.day = this.$moment(this.day).add(number, 'month').toISOString()

        }
    },
    computed: {
        showDate(){
            return this.$store.getters.getShowDate
        },
        showProjects(){
            return this.$store.getters.showProjects
        },
        showCalculations(){
            return this.$store.getters.showCalculations

        },

        showAccess() {
            return this.$store.getters.showAccess

        },

    },
    data(){
        return{
            day: this.$moment().toISOString(),
            route: 'buchung'
        }
    }
}
</script>
<style>
.user{
    display: flex;
    flex-direction: column;
    flex: 1 0 0;

}
h2.header{
    margin-bottom: 0;
}
.vdatetime-input{
    font-size: 14pt;
    text-align: center;

}
.hide-input .vdatetime-input{
    display: none;
}
.datetime .hide-input input{
    display: none;
}
.md-bottom-bar>.md-ripple {
    display: flex;
    flex-wrap: nowrap; 
    overflow-x: scroll;
}
.vdatetime-calendar__month__day--selected > span > span, .vdatetime-calendar__month__day--selected:hover > span > span{
    background-color: var(--color2);
}
.icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
</style>