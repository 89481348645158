<template>
    <div class="vertical-wrapper" >
        <user-files-header :timescale.sync="timeScale" v-on:refresh="reloadTable" v-on:export="exportPDF"></user-files-header>
        <div class="horizontal-wrapper" style="overflow-y: scroll;">
            <div></div>
            <vue-html2pdf :html-to-pdf-options="{
                showLayout: false,
                floatLayout: false,
                enableDownload: true,
                previewModal: false,
                paginateElementsByHeight: 1400,
                margin: 5,
                filename: 'Krankheitstage-' + this.$moment(this.$getdate()).format('MMMM-YYYY') + '.pdf',
                pdfQuality: 2,
            
                jsPDF: {
                    unit: 'mm',
                    format: 'a4',
                    orientation: 'portrait'
                }
            }" :show-layout="false" :float-layout="false" :enable-download="true" :preview-modal="false"
                :paginate-elements-by-height="1400"
                :filename="'Krankheitstage-' + this.$moment(this.$getdate()).format('MMMM-YYYY') + '.pdf'" :pdf-quality="2" :margin="5"
                pdf-format="a4" pdf-orientation="portrait" pdf-content-width="730px"
                @hasStartedGeneration="hasStartedGeneration()" v-on:hasDownloaded="generatePFD = false" ref="html2Pdf">
                <section slot="pdf-content" class="pdf-content" >
                    <div class="horizontal-wrapper" style="width: 720px" :class="{ 'hide': !generatePFD }">
                        <div class="vertical-wrapper">
                            <h4>Dokumentation der</h4>
                            <h4>Krankheitstage</h4>

                        </div>
                        <div class="vertical-wrapper">
                            <h4>Zeitraum:</h4>
                            <h4>{{ this.$moment(this.date).format(dateFormat) }}</h4>

                        </div>
                        <div class="placeholder"></div>
                        <div class="logo icon" style="flex: 0 0 100pt; ">
            
                        </div>
            
                    </div>
                    <div ref="pdf-table" class="times-table">
            
                    </div>
            
                </section>
            
            </vue-html2pdf>
            <div></div>

        </div>
    </div>
</template>
<script>
import userFilesHeaderVue from '../../components/user-file-header.vue'
import VueHtml2pdf from 'vue-html2pdf'

import jexcelStyle from "jexcel/dist/jexcel.css"; // eslint-disable-line no-unused-vars
import jexcel from "jexcel"; // eslint-disable-line no-unused-vars

export default {
    name: 'user-files-sick',
    components: {
        'user-files-header':userFilesHeaderVue,
        VueHtml2pdf

    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.path == '/') {
                vm.$socket.emit('get-users', { token: vm.$gettoken() })
                return
            }

            let seletion_saved = JSON.parse(vm.$cookies.get('user-selection'))
            if (!seletion_saved)
                seletion_saved = []
            vm.$socket.emit('get-users-sick-days', { token: vm.$gettoken(), date: vm.date, selection: seletion_saved })

            next();
        })
    },
    mounted: function () {
        this.spreadsheet = jexcel(this.$refs["pdf-table"], this.options);
        Object.assign(this, this.spreadsheet);
    },
    methods:{
        reloadTable(){
            this.$nextTick(() => {
                this.$socket.emit('get-users-sick-days', {token: this.token, date: this.date, userSelection: this.userSelection, timeScale: this.timeScale})
            })
        },
        exportPDF(){
            this.generatePFD = true

            this.$refs['html2Pdf'].generatePdf()
        },
        hasStartedGeneration(){
            this.generatePFD = false
        }
    },
    sockets: {
        'set-users-sick-days': function({ table }){
            this.spreadsheet.setData(table.map(e => [e.name, e.sick, e.childSick]))
        }
    },
    watch: {
        date() {
            this.reloadTable()
        }
    },
    computed:{
        token(){
            return this.$store.getters.token
        },
        userSelection(){
            return this.$store.getters.getUserSelection
        },
        date(){
            return this.$store.getters.getDate
        },
        dateFormat(){
            if (this.timeScale == 'month')
                return 'MMMM-YYYY'
            else if (this.timeScale == 'year')
                return 'YYYY'
            return '' 
        }
    },
    data(){
        return{
            timeScale: 'month',
            generatePFD: false,
            options: {
                data: [['', '', '']],
                columns: [

                    { title: 'Name', width: 200 },

                    { title: 'Krankheitstage', width: 150 },

                    { title: 'Kind Krank', width: 150 },


                ]
            },
        }
    }

}
</script>