<template>
    <div>
        <h3>Formeln</h3>
        <div class="vertical-wrapper">
            <md-field style="margin: 0 15pt; flex: 1 0 0; width: auto;">
                <label for="">suchen</label>
                <md-input v-model="searchString"></md-input>
            </md-field>
        </div>
        
        <div class="vertical-wrapper" style="overflow-y: scroll">
            <calculation v-for="c in calculations.filter(e => e.name.includes(searchString))" :key="c.key" :calculation="c"></calculation> 
        </div>
        <div>
            <md-button v-if="isadmin" class="md-primary" v-on:click="addCalc">hinzufügen</md-button>
        </div>

    </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';

import calculation from '../components/calculation.vue'
export default {
    name: 'user-calculations',
    props: ['id',  'name'],
    components: { calculation },
    methods: {
        addCalc(){
            this.calculations.push({id: -1, name: '', open: true, key:uuidv4()})
        }
    },
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            vm.$store.commit('set-show-date', false)

            if(from.path == '/')
                vm.$socket.emit('get-users', {token:vm.$gettoken()})
            
            if(vm.$props.id){

                vm.$socket.emit('get-calculations', {token: vm.$gettoken()})
            }

            next();
        }) 
    },
    computed:{
        isadmin(){
            return this.$store.getters.isadmin
        },
        user(){
            return this.$store.getters.getUser
        },
        date(){
            return this.$store.getters.getDate
        }
    },
    sockets: {
        'set-calculations': function({calculations}){
            calculations.forEach(c =>{ c.key = uuidv4(); c.open = false});
            this.calculations = calculations
        }
    },
    data(){
        return{
            calculations:[


            ],
            searchString: ''
        }
    }
}
</script>