<template>
    <div class="horizontal-wrapper project-header" style="flex: 0 0 auto ">
        <div class="vertical-wrapper" style="flex: 0.5 0 0">
            <h4 style="font-weight: normal">Projektnr:</h4>
            <h4 style="font-weight: normal">Projektname:</h4>
        </div>
        <div class="vertical-wrapper" style="flex: 0.5 0 0">
            <h4>{{ projectnr }}</h4>
            <h4>{{ projectname }}</h4>
        </div>
        <div class="vertical-wrapper" style="flex: 0.5 0 0">
            <h4 style="font-weight: normal">Erstellt am:</h4>
            <h4 style="font-weight: normal">Stunden gesamt:</h4>
        </div>
        <div class="vertical-wrapper" style="flex: 0.5 0 0">
            <h4>{{ projectCreated }}</h4>
            <h4>{{ projectHours }}h</h4>
        </div>

        <div class="vertical-wrapper" style="flex: 0.5 0 0">
            <h4 style="font-weight: normal">Laufzeit:</h4>
            <h4 style="font-weight: normal">Start - Ende:</h4>
        </div>
        <div class="vertical-wrapper" style="flex: 1 0 0">
            <h4>{{ projectRuntime }} Tage</h4>
            <h4>{{ projectStartEndTime }}</h4>
        </div>
        <div class="logo" style="flex: 0 0 70pt" :class="{ 'hide': !pdfgenerating }"> </div>
    </div>
</template>
<script>
export default {
    name: 'project-header',
    props: { 
        projectnr: String,
        projectname: String, 
        projectCreated: String, 
        projectHours: String, 
        projectRuntime: [String, Number], 
        projectStartEndTime: String, 
        pdfgenerating:Boolean}
}
</script>
<style>
.project-header h4{
    font-size: 12pt;
}
</style>