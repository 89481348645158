var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vertical-wrapper"},[_c('user-files-header',{attrs:{"timescale":_vm.timeScale},on:{"update:timescale":function($event){_vm.timeScale=$event},"refresh":_vm.reloadTable,"export":_vm.exportPDF}}),_c('div',{staticClass:"horizontal-wrapper",staticStyle:{"overflow-y":"scroll"}},[_c('div'),_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"html-to-pdf-options":{
            showLayout: false,
            floatLayout: false,
            enableDownload: true,
            previewModal: false,
            paginateElementsByHeight: 1400,
            margin: 5,
            filename: 'Krankheitstage-' + this.$moment(this.$getdate()).format('MMMM-YYYY') + '.pdf',
            pdfQuality: 2,
        
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait'
            }
        },"show-layout":false,"float-layout":false,"enable-download":true,"preview-modal":false,"paginate-elements-by-height":1400,"filename":'Krankheitstage-' + this.$moment(this.$getdate()).format('MMMM-YYYY') + '.pdf',"pdf-quality":2,"margin":5,"pdf-format":"a4","pdf-orientation":"portrait","pdf-content-width":"730px"},on:{"hasStartedGeneration":function($event){return _vm.hasStartedGeneration()},"hasDownloaded":function($event){_vm.generatePFD = false}}},[_c('section',{staticClass:"pdf-content",attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('div',{staticClass:"horizontal-wrapper",class:{ 'hide': !_vm.generatePFD },staticStyle:{"width":"720px"}},[_c('div',{staticClass:"vertical-wrapper"},[_c('h4',[_vm._v("Dokumentation der")]),_c('h4',[_vm._v("Krankheitstage")])]),_c('div',{staticClass:"vertical-wrapper"},[_c('h4',[_vm._v("Zeitraum:")]),_c('h4',[_vm._v(_vm._s(this.$moment(this.date).format(_vm.dateFormat)))])]),_c('div',{staticClass:"placeholder"}),_c('div',{staticClass:"logo icon",staticStyle:{"flex":"0 0 100pt"}})]),_c('div',{ref:"pdf-table",staticClass:"times-table"})])]),_c('div')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }