<template>
    <div class="vertical-wrapper">
        <div class="horizontal-wrapper">
            <md-field>
                <label for="">Profil</label>
                <md-select v-on:md-selected="changeLabourProtectionProfil" :value="activeProfileKey">
                    <md-option v-for="p in profiles" :key="p.key" :value="p.key">{{p.name}}</md-option>
                    <md-option :value="'new'">+</md-option>

                </md-select>
            </md-field>
        </div>
        <div class="vertical-wrapper" :class="{ 'hide': !showProfileSettings }">
            <div class="horizontal-wrapper">
                <md-field style="flex: 2 0 0; margin-right: 5pt;">
                    <label for="">Bezeichung</label>
                    <md-input v-model="selectedProfil.name"></md-input>
                </md-field>
                <md-field style="flex: 1 0 0; margin-left: 5pt;" >
                    <label for="">Behandlung</label>

                    <md-select v-model="selectedProfil.handler">
                        <md-option :value="'info'">Buchung zulassen, mit Info</md-option>
                        <md-option :value="'reject'">Buchung nicht zulassen</md-option>
                    </md-select>
                </md-field>
            </div>
           
            <div class="horizontal-wrapper">
                <div class="vertical-wrapper" style="margin-right: 5pt">
                    <h4>§ 3 Arbeitszeit der Arbeitnehmer</h4>
                    <md-field style="flex: 0 0 auto">
                        <label for="">maximale Arbeitszeit [h]</label>
                        <md-input type="numeric" v-model="selectedProfil.maxWorkTime"></md-input>
                        <span class="md-helper-text">maximale Arbeitszeit die pro Tag gearbeitet werden darf</span>
                    </md-field>
                    <div class="horizontal-wrapper">
                        <md-field style="flex: 0 0 auto; flex: 1 0 0 ; margin-right: 2.5pt;">
                            <label for="">frühester Arbeitsbeginn [HH:mm]</label>
                            <md-input type="numeric" v-model="selectedProfil.earliestStartPoint"></md-input>
                            <span class="md-helper-text"></span>
                        </md-field>
                        <md-field style="flex: 0 0 auto; flex: 1 0 0 ; margin-left: 2.5pt;">
                            <label for="">spätestes Arbeitsende [HH:mm]</label>
                            <md-input type="numeric" v-model="selectedProfil.latestEndPoint"></md-input>
                            <span class="md-helper-text"></span>
                        </md-field>
                    </div>
                    
                </div>
                <div class="vertical-wrapper" style="margin-left: 5pt;">
                    <h4>§ 4 Ruhepausen</h4>
                    <div ref="breaktime-jexcel" style=" max-height: 150pt; overflow-y: scroll;">

                    </div>
                   
                </div>
             
            </div>
            <div class="horizontal-wrapper">
                <div class="vertical-wrapper" style="margin-right: 5pt">
                    <h4>§ 5 Ruhezeit</h4>
                    <md-field>
                        <label for="">minimale Ruhezeit [h]</label>
                        <md-input type="numeric" v-model="selectedProfil.minIdleTime"></md-input>
                        <span class="md-helper-text">mininmale Zeit in Stunden zwischen Arbeitsende und Arbeitsbeginn am Folgetag</span>
                    </md-field>

                </div>
            </div>
          

           
        </div>
    </div>
</template>
<script>
import jexcelStyle from "jexcel/dist/jexcel.css"; // eslint-disable-line no-unused-vars
import jexcel from "jexcel"; // eslint-disable-line no-unused-vars

export default{
    name: 'labour-protection-settings',
    methods: {
        showInfo(text){
            this.$swal.fire({
                icon: 'info',
                title: 'Info',
                text: text
            })
        },
        changeLabourProtectionProfil(e){
            if( e == 'new'){
                this.$swal.fire({
                    icon: 'question',
                    title: 'Sicher',
                    text: 'Neues Arbeitsschutz Profil hinzufügen',

                    showConfirmButton: true,
                    confirmButtonText: 'Ja',

                    showDenyButton: true,
                    denyButtonText: 'Nein'
                }).then(e => {
                    if (e.isConfirmed) {
                        const k = this.$uuid.v4()
                        this.profiles.push({
                            id: -1,
                            name: 'Profil ' + (this.profiles.length), key: k,
                            handler: 'info',
                            maxWorkTime: '0',
                            minIdleTime: '0',
                            breakTimes: [[-1, '', '']]

                        })
                        this.activeProfileKey = k
                        this.$nextTick(() => {
                            this.spreadsheet.setData(this.selectedProfil.breakTimes)

                        })
                        

                    }
                })
            }else{


                this.activeProfileKey = e;
                if (this.selectedProfil.breakTimes)
                    this.spreadsheet.setData(this.selectedProfil.breakTimes.map(e => [e.id, e.breaktime, e.worktime]))
            }
       
        },
        onafterchanges(){
            const data = this.spreadsheet.getData().map(e => {return {id: e[0] == ''? -1: parseInt(e[0]), breaktime: e[1], worktime: e[2]}})
            this.selectedProfil.breakTimes = data
        },
        save(){
           

            this.$socket.emit('edit-labour-protection-profiles', { token: this.token, user: this.user, selectedKey: this.activeProfileKey, profiles: this.profiles})
        }
       
    },
    computed:{
        selectedProfil(){
            const p = this.profiles.find(e => e.key == this.activeProfileKey)
            if(!p)
                return {}
            return p
        },
        showProfileSettings() {
            const p = this.profiles.find(e => e.key == this.activeProfileKey)
            return p ? p.id : 0
        },

        token(){
            return this.$store.getters.token;
        },
        user() {
            return this.$store.getters.getUser
        },
    },
    sockets: {
        'set-labour-protection-profiles': function ({ profiles, IDh_labour_protection_profile }){
            profiles.forEach(e => e.key = this.$uuid.v4())
            profiles.push({ id: 0, name: 'keine Überprüfung', key: this.$uuid.v4() })
            this.profiles = profiles;

            const p = profiles.find(e => e.id == IDh_labour_protection_profile)
            if(!p)
                this.activeProfileKey = profiles[0].key
            else
                this.activeProfileKey = p.key;
        }
    },
    data(){
        const k = this.$uuid.v4()
        return{
            activeProfileKey: k,
            profiles: [

                { id: 0, name: 'kein Überprüfung', key: k }, 

            ],
            tableOptions: {
                data: [['']],
                columns: [
                    { title: 'id', width: 100, type: 'hidden' },

                    { title: 'min Pause [min]', width: 150},
                    { title: 'Pause nach [h]', width: 150 },

                ],
                onafterchanges: this.onafterchanges

            },
            spreadsheet: 0
        }
    },
    mounted(){
        this.spreadsheet = jexcel(this.$refs['breaktime-jexcel'], this.tableOptions)
    }
}
</script>