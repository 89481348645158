<template>
    <div class="vertical-wrapper">
        <div class="horizontal-wrapper" style="flex:0 0 auto;">
            <div class="placeholder"></div>
            <md-field style="flex: 2 0 0">
                <label for="">Suchen</label>
                <md-input v-model="searchString"></md-input>
            </md-field>
            <div class="placeholder"></div>
        </div>
        <div v-for="p in projects.filter(e => e.name? e.name.toLowerCase().includes(searchString.toLowerCase()): false)" :key="p.key" class="horizontal-wrapper" style="flex:0 0 auto;">
            <div class="placeholder"></div>
            <project-evaluation-details :project="p" :departments="departments" :users="users" style="flex: 2 0 0"></project-evaluation-details>
            <div class="placeholder"></div>
        </div>
    </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import projectEvaluationDetailsVue from '../../components/project-evaluation-details.vue';

export default {
    name: 'user-files-projects',
    components: {
        'project-evaluation-details':projectEvaluationDetailsVue
    },
    sockets: {
        'set-projects-evaluation':function({projects, departments, users}){
            projects.forEach(p => p.key = uuidv4());
            this.projects = projects;

            this.users = users;
            this.departments = departments

        },
    },
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            if(from.path == '/'){
                vm.$socket.emit('get-users', {token:vm.$gettoken()})
                return
            }
            
            vm.$socket.emit('get-projects-evaluation', { token: vm.$gettoken() })

            next();
        }) 
    },
    data(){
        return{
            searchString: '',
            projects: [],
            users: [],
            departments: [],
        }
    }
}
</script>
