<template>
    <div class="">
        <div v-ripple class="horizontal-wrapper" v-on:click="$socket.emit('get-access-details', {token: $gettoken(), id:access.id}); showDialog = true">
            <h4>{{access.name}}</h4>
        </div>
         <md-dialog :md-active.sync="showDialog">
            <div class="horizontal-wrapper" style="flex: 0 0 auto;">
                <md-dialog-title style="display: inline">Zugang</md-dialog-title>
                
                <md-dialog-actions>
                    <md-button class="md-primary" @click="showDialog = false; $emit('access-close')">Close</md-button>
                    <md-button class="md-primary" @click="save_access(); showDialog = false; ">Save</md-button>
                </md-dialog-actions>
            </div>
             
            <div class="vertical-wrapper" style="margin: 20px 30px">
            <md-field style="flex: 0 0 auto;">
                    <label>Bezeichner</label>
                    <md-input v-model="name" ></md-input>
            </md-field>


            <md-field style="flex: 0 0 auto;">
                    <label>Benutzername</label>
                    <md-input v-model="un" ></md-input>
            </md-field>
           
            <md-field style="flex: 0 0 auto;">
                    <label>Passwort</label>
                    <md-input v-model="pw" ></md-input>
            </md-field>

            <div class="users-list vertical-wrapper" >
                <selection-list :items="user" :top-selection-all="true" :search-bar="true" v-on:item-selection-change="(list) => newList = list"></selection-list>
            </div>
           
            </div>
           
        </md-dialog>
    </div>
</template>
<script>
import selectionListVue from './VueComponents/selection-list.vue'
export default {
    name: 'access-component',
    props: {access:Object},
    components:{'selection-list':selectionListVue},
    methods:{
        save_access(){
            this.$socket.emit('save-access-details', {token:this.$gettoken(), access:{id: this.$props.access.id, name:this.name, un:this.un, pw:this.pw}, user:this.newList})
        }
    },
    sockets:{
        'set-access-details':function({access, user}){
            this.un = access.un;
            this.pw = access.pw;
            user.forEach(e => e.active = e.active? true: false);
            this.user = user
            this.newList = user

        }
    },
    data(){
        return{
            showDialog: this.$props.access.id < 0,
            name : this.$props.access.name,
            un: '', pw: '',
            user: [{id: 0 , name: 'Andi', active: false}, {id: 0 , name: 'Fation', active: false}, {id: 0 , name: 'Hans', active: false}],
            newList: []
        }
    }, mounted(){
        if(this.$props.access.id < 0){
            this.$socket.emit('get-access-details', {token: this.$gettoken(), id:this.access.id})
        }
    }
}
</script>