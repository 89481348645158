<template>
    <div class="horizontal-wrapper" :class="{'hide':!locked}">
            <div></div>
            <md-avatar style="flex: 0 0 auto; padding:9.5px; margin:7px; margin-right: 20pt"  class="md-avatar-icon md-primary">
                <img style="color:white" src="../assets/lock-solid.svg" alt="Avatar">
            </md-avatar>
            <h2 style="flex: 0 0 auto;">Monat abgeschlossen</h2>
            <div></div>

    </div>
</template>
<script>
export default {
    name: 'month-closed-tag',
    props: { 'locked':Boolean }
}
</script>