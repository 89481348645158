<template>
    <div>
        <loginpage hide_register="true" open_at_start="true" v-on:login="login"></loginpage>
    </div>
</template>
<script>
import Loginpage from '../components/VueComponents/loginpage.vue'
export default {
    name:'login-view',
    components:{
        Loginpage

    },
    methods:{
        login({email, password}){
            this.$socket.emit('login',{name:email, password:password});
        },
    },
    sockets:{
        'login-failed':function(){
            this.$swal({
                icon:'error',
                title:'Login failed', 
                text:'wrong login creditals', 
           })
        },
     
    }
}
</script>